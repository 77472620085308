import * as React from 'react'
import styles from './Placeholder.module.css'

export const Placeholder = ({
  emoji,
  text,
}: {
  emoji: string
  text: string
}) => (
  <div className={styles.container}>
    <div className={styles.emoji}>{emoji}</div>
    <div className={styles.spacer} />
    <div>{text}</div>
  </div>
)
